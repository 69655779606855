import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import 'jquery'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'jquery-browserify'

import "iframe-resizer"
import "js/plugins/iframeResizer.contentWindow.min.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'js/widget/agb'
import 'js/widget/prevent_enter'
import 'js/widget/reserveme'
import 'js/widget/autosize'
import 'js/widget/date_pickers'

