
$(document).on('turbolinks:load', function(event) {
    $('#agb').on('change', function () {
       if(this.checked) {
           $('#submitbutton').removeClass('disabled')
           $('#submitbutton').prop('disabled', false)
       } else {
           $('#submitbutton').addClass('disabled')
           $('#submitbutton').prop('disabled', true)
       }
    });
});
