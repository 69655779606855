
$(document).on('turbolinks:load', function(event) {

    $('#booking_reservation').on('change', function () {
       if(this.checked) {
           $('#book_me').hide()
           $('#reserve_me').show()
       } else {
           $('#book_me').show()
           $('#reserve_me').hide()
       }
    });
});
