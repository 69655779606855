import { DateTime } from 'luxon';

import 'webpack-jquery-ui'
import 'webpack-jquery-ui/css'

var checkPrice = function() {
    var checkboxes = $(':checkbox:checked');
    var room_ids = []
    var option_ids = []
    var highlite_ids = []

    var start = $('#booking_booking_start').val()
    var ende = $('#booking_booking_end').val()

    if( start !== undefined && ende !== undefined ) {
        checkboxes.each(function () {
            var name = $(this).attr('name');
            var id = $(this).attr('value');

            if (name == 'booking[room_ids][]') {
                room_ids.push(id);
            }

            if (name == 'booking[option_ids][]') {
                option_ids.push(id);
            }
            if (name == 'booking[highlite_ids][]') {
                highlite_ids.push(id);
            }
        })

        $.ajax({
            type: "POST",
            url: '/widget/check_price',
            data: {
                start: $('#booking_booking_start').val(),
                ende: $('#booking_booking_end').val(),
                guests: $('#booking_guests').val(),
                name_first: $('#booking_customer_attributes_name_first').val(),
                name_last: $('#booking_customer_attributes_name_last').val(),
                email: $('#booking_customer_attributes_email').val(),
                mobile_phone: $('#booking_customer_attributes_mobile_phone').val(),
                member_card_id: $('#booking_customer_attributes_member_card_id').val(),
                room_ids: room_ids,
                option_ids: option_ids,
                highlite_ids: highlite_ids
            },
            success: function (data) {
                $('#price_table').html(data)
            },
            dataType: 'html'
        });


        $.ajax({
            type: "POST",
            url: '/widget/check_appointment',
            data: {start: $('#booking_booking_start').val(), ende: $('#booking_booking_end').val()},
            success: function (data) {
                if (data.length > 0) {
                    var text = "Die Auswahl kolidiert mit " + data.length + " Buchung(en).\n";
                    $.each(data, function (index, element) {

                        text = text + "\n" + (index + 1) + ".: " + element['start'] + " bis " + element['ende']
                    })
                    text = text + "\n\n" + "Bitte bedenken Sie auch unsere Reinigungs- und Vorbereitungszeiten von ca. 4 Stunden zwischen den Buchungen."
                    alert(text)
                }
            },
            dataType: 'json'
        });
    }
};




$(document).on('turbolinks:load', function(event) {
    checkPrice();

    $(':checkbox, ' +
        '#booking_guests, ' +
        '#booking_customer_attributes_email, ' +
        '#booking_customer_attributes_name_first, ' +
        '#booking_customer_attributes_name_last, ' +
        '#booking_customer_attributes_mobile_phone, ' +
        '#booking_customer_attributes_member_card_id').change(function() {
        checkPrice()
    });

    $('#start_time').on('change', function () {
        $('#booking_booking_start').val($('#booking_start_date').val() + " " + $('#start_time').val() + ":00")
        checkPrice();
    })

    $('#booking_start_date').datepicker({
        constrainInput: true,
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        minDate: new Date(),
        onSelect: function (date) {
            $('#booking_booking_start').val(date+" "+$('#start_time').val()+":00");

            $('#booking_end_date').val(date);
            $('#booking_booking_end').val($('#booking_end_date').val() + " " + $('#end_time').val() + ":00")
            checkPrice();
        }
    });

    $('#end_time').on('change', function () {
        $('#booking_booking_end').val($('#booking_end_date').val() + " " + $('#end_time').val() + ":00")
        checkPrice();
    })

    $('#booking_end_date').datepicker({
        constrainInput: true,
        dateFormat: "yy-mm-dd",
        firstDay: 1,
        minDate: new Date(),
        onSelect: function (date) {
            $('#booking_booking_end').val(date+" "+$('#end_time').val()+":00");
            checkPrice();
        }
    });

    jQuery(function($){
        $.datepicker.regional['de'] = {clearText: 'löschen', clearStatus: 'aktuelles Datum löschen',
            closeText: 'schließen', closeStatus: 'ohne Änderungen schließen',
            prevText: '<zurück', prevStatus: 'letzten Monat zeigen',
            nextText: 'Vor>', nextStatus: 'nächsten Monat zeigen',
            currentText: 'heute', currentStatus: '',
            monthNames: ['Januar','Februar','März','April','Mai','Juni',
                'Juli','August','September','Oktober','November','Dezember'],
            monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
                'Jul','Aug','Sep','Okt','Nov','Dez'],
            monthStatus: 'anderen Monat anzeigen', yearStatus: 'anderes Jahr anzeigen',
            weekHeader: 'Wo', weekStatus: 'Woche des Monats',
            dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
            dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
            dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
            dayStatus: 'Setze DD als ersten Wochentag', dateStatus: 'Wähle D, M d',
            dateFormat: 'dd.mm.yy', firstDay: 1,
            initStatus: 'Wähle ein Datum', isRTL: false};
        $.datepicker.setDefaults($.datepicker.regional['de']);
    });
})
